const { web3 } = require('../web3');
const { env } = require('../config');

const ICOData = require(`./${env}/ICO.json`);
const TokenData = require(`./${env}/Token.json`);
const StableTokenData = require(`./${env}/StableCoin.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const ICOABI = ICOData['abi'];
const ICOAddress = ICOData['address'];
const ICO = new web3.eth.Contract(ICOABI, ICOAddress);

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const StableTokenABI = StableTokenData['abi'];
const StableTokenAddress = StableTokenData['address'];
const StableToken = new web3.eth.Contract(StableTokenABI, StableTokenAddress);

module.exports = {
  ICO, ICOABI, ICOAddress,
  Token, TokenABI, TokenAddress,
  StableToken, StableTokenABI, StableTokenAddress
};
