import EventBus from 'eventing-bus';
import { Button } from 'reactstrap';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import MuiPhoneInput from 'material-ui-phone-number';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import countries from './country.json';
import { web3 } from '../../store/web3';
import { ICO } from '../../store/contract';
import { message, networkId, explorer } from '../../store/config';

import { login, isLoggedIn, logout, toggleSignup, toggleSignupInfo, signup, toggleConnectModal, toggleSwitchModal } from "../../store/actions/Auth";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: '',
                email: '',
                country: '',
                phone: '',
                type: 'signup',
                publicAddress: '',
            },
            isLogout: false,
            countryCode: 'us',
        };
    };

   

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            let { isPolicyModal } = this.props;
            if (!isPolicyModal) this.props.toggleConnectModal(true);
            return;
        }
        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                let { isPolicyModal } = this.props;
                if (!isPolicyModal) this.props.toggleSwitchModal(true);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.isLoggedIn(true);
            this.props.login({ publicAddress: address, type: 'login' });
        });
    };

    copied = () => EventBus.publish("success", 'Wallet Address Copied');
    toggleLogoutModal = () => this.setState({ isLogout: !this.state.isLogout });

    logoutWallet = () => {
        this.props.logout();
        this.setState({ isLogout: !this.state.isLogout });
    };

    handleChange = (event) => {
        let { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    };

    setPhoneNumber = (number) => {
        let { formData } = this.state;
        formData['phone'] = number;
        this.setState({ formData });
    };

    selectedCountry = (e, value) => {
        let { formData } = this.state;
        formData['country'] = value['label'];
        // this.setState({ formData });
        this.setState({ formData, countryCode:  value['code'].toLowerCase() });
    };

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    };

    submitSignupInfo = () => {
        let { formData } = this.state;
        if (formData['name'] == '') {
            EventBus.publish("error", 'Please enter the Name');
            return;
        }
        else if (formData['email'] == '') {
            EventBus.publish("error", 'Please enter the Email');
            return;
        }

        else if (formData['country'] == '') {
            EventBus.publish("error", 'Please select the Country');
            return;
        }

        else if (formData['phone'] == '') {
            EventBus.publish("error", 'Please enter the Phone Number');
            return;
        }

        else this.props.toggleSignupInfo();
    };

    submitSignup = () => {
        if (typeof window.ethereum === 'undefined') {
            let { isPolicyModal } = this.props;
            if (!isPolicyModal) this.props.toggleConnectModal(true);
            return;
        }
        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                let { isPolicyModal } = this.props;
                if (!isPolicyModal) this.props.toggleSwitchModal(true);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            let { formData } = this.state;
            formData['publicAddress'] = address;
            this.setState({ formData },
                () => this.props.signup(formData));
        });
    };

    render() {
        let { isLogout, formData, countryCode } = this.state;
        let { address, isLogin, isSignup, isSignupInfo } = this.props;
        return (
            <nav className="navbar navbar-expand-lg">
                <div className="container ">
                    <Link className="navbar-brand" to="#"><img src={require("../../static/images/landing/06.png")} alt="" /></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <i className="fa fa-bars " aria-hidden="true"></i>
                    </button>
                    <div className="collapse navbar-collapse nav-links" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <HashLink className='nav-link ' smooth to='/#about'>ABOUT</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className='nav-link ' smooth to='/#why-how'>WHY & HOW </HashLink>
                            </li>
                            {/* <li className="nav-item">
                                <HashLink className='nav-link ' smooth to='/#team'>TEAM</HashLink>
                            </li> */}
                            <li className="nav-item">
                                <HashLink className='nav-link ' smooth to='/#token'>TOKEN</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className='nav-link ' smooth to='/#roadmap'>ROADMAP</HashLink>
                            </li>
                        </ul>
                        <div className="row ml-auto button-head">
                            {address == null || address == ''
                                ?

                                <button onClick={this.connectWallet}>
                                    {!isLogin
                                        ? 'Login'
                                        : <div className='login-loader'><i className='fa fa-spinner fa-spin fa-1x fa-fw' /></div>
                                    }

                                </button>
                                : <button onClick={this.toggleLogoutModal} style={{ fontSize: '15px' }}>
                                    {address && address.substring(0, 9) + '.....'}
                                </button>
                            }
                            {!address &&
                                <button className="button-sign" onClick={this.props.toggleSignup}>Sign Up</button>
                            }
                        </div>
                    </div>
                </div>

                {/* ------------------SIGN-UP MODAL----------------- */}
                <Modal isOpen={isSignup} toggle={this.props.toggleSignup} className="register-modal signup-modal">
                    <ModalBody className="modal-body">
                        <ModalHeader toggle={this.props.toggleSignup}>
                        </ModalHeader>
                        <div className="icon-area-modal-pur">
                            <img src={require("../../static/images/landing/06.png")} alt="" />
                        </div>
                        <div className="container modal-upper-pad">
                            <div className="form-modal-landing-silver">
                                <div className="input-feild-button">
                                    {!isSignupInfo
                                        ? <ValidatorForm className="row validator-form my-4" onSubmit={this.submitSignupInfo}>
                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 offset-0 text-feild-modal">
                                                <TextValidator
                                                    className="login-input-fields col-lg-12"
                                                    type="text"
                                                    name="name"
                                                    label={`Name *`}
                                                    margin="dense"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    value={formData['name']}
                                                    validators={['required']}
                                                    errorMessages={['Please enter the Name']}
                                                />
                                                <TextValidator
                                                    className="login-input-fields col-lg-12"
                                                    type="email"
                                                    name="email"
                                                    label="Email *"
                                                    margin="dense"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    value={formData['email']}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['Please enter the Email', 'email is not valid']}
                                                />
                                                <Autocomplete
                                                    fullWidth
                                                    id="country-select-demo"
                                                    className="autocomplete-field"
                                                    options={countries}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, newValue) => this.selectedCountry(event, newValue)}
                                                    renderOption={(option) => (
                                                        <React.Fragment>
                                                            <span>{this.countryToFlag(option.code)}</span>
                                                            &nbsp;&nbsp; {option.label} ({option.code})
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Country'
                                                            margin="dense"
                                                            variant="outlined"
                                                            className="login-input-fields col-lg-12"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <MuiPhoneInput
                                                    fullWidth
                                                    name="phone"
                                                    margin="dense"
                                                    variant="outlined"
                                                    autoFormat={false}
                                                    label='Phone Number'
                                                    id='standard-full-width'
                                                    value={formData['phone']}
                                                    defaultCountry={countryCode}
                                                    className="login-input-fields col-lg-12 "
                                                    onChange={(number) => this.setPhoneNumber(number)}
                                                />
                                            </div>
                                            <div className='col-12 pt-2 text-center'>
                                                <Button type="Submit" className='button-form-modal text-center text-black login-btn'>Next</Button>
                                            </div>
                                        </ValidatorForm>
                                        : <div className='col-12 pt-2 text-center text-paragraph-connect-wallet'>
                                            <div className="connect-wallat-signup">
                                                <div className="meta-icon-imag-text ">
                                                    <div className="flex-display-wrapping">
                                                        <Link >
                                                            <img src={require("../../static/images/landing/022.jpg")} className="img-trust-wallet" alt="" />
                                                        </Link>
                                                        <Link onClick={this.submitSignup}>
                                                            <img src={require("../../static/images/landing/033.jpg")} className="img-trust-wallet-two" alt="" />
                                                        </Link>
                                                    </div>

                                                    <div className="text-meta-mask-signup">
                                                        <h2>How to setup Metamask?<span><a className='learn-meta-signup' target='_blank' href='https://levelup.gitconnected.com/how-to-use-metamask-a-step-by-step-guide-f380a3943fb1'>Learn More</a></span></h2>
                                                        <h5 className="text-white sheild-icon-siz"><span><img src={require("../../static/images/landing/sheild-icon.png")} alt="" /></span>Non-custodial & Secure</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>We do not own your private keys and cannot access your funds <br></br>without your confirmation</p>
                                            <Button onClick={this.submitSignup} className='button-form-modals text-center text-black login-btn'>
                                                Connect Wallet
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                {/* ------------------LOGOUT MODAL----------------- */}
                <Modal isOpen={isLogout} toggle={this.toggleLogoutModal} className="register-modal logout-modal">
                    <ModalBody className="modal-body" >
                        <ModalHeader toggle={this.toggleLogoutModal}></ModalHeader>
                        <div className="icon-area-modal-pur">
                            <img src={require("../../static/images/landing/06.png")} alt="" />
                        </div>
                        <div className="container modal-upper-pad">
                            <div className="text-center form-modal-landing-silver">
                                <div className="copy-address"><p>{address}</p></div>
                            </div>
                            <div className="modal-btn">
                                <CopyToClipboard text={address} onCopy={this.copied}>
                                    <button>Copy <span><i class="fa fa-files-o" aria-hidden="true"></i></span></button>
                                </CopyToClipboard>
                                <button onClick={this.logoutWallet}>Logout <span><i class="fa fa-sign-out" aria-hidden="true"></i></span></button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </nav>
        );
    }
}

const mapDispatchToProps = { login, isLoggedIn, logout, toggleSignup, toggleSignupInfo, signup, toggleConnectModal, toggleSwitchModal };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, isSignup, isSignupInfo, address, isPolicyModal } = Auth;
    return { isLogin, isSignup, isSignupInfo, address, isPolicyModal }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);