import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class WhyChooseUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="container  mb-5">
              <div className="row">
                <div className="col-lg-12 categories_main_upper">
                  <h4 className="text-center text-heading">Why Choose us?</h4>
                  <div className="row no-gutters pt-4 pb-5 gutter-cont">
                    <div className="col-md-4  card-padding-right colum-1">
                      <div className="row colum1-image">
                        <div className="col-md-12 col-sm-12 col-12 position_images ">
                          <div className="colum1-image"><img src={require("../../static/images/landing/vectorimage1.png")} alt="" /></div>
                          <div className="card mt-4 wow fadeInLeft" data-wow-delay='100ms'>
                            <div className="image-div"><figure><img src={require("../../static/images/landing/customer1.png")} alt="" /></figure></div>
                            <h4>Charity</h4>
                            <p>United World is a non profit organisation which aims to introduce new ideas of running operations in the charity ecosystem through blockchain using UWT token.</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 position_images mt-3">
                          <div className="card wow wow fadeInLeft" data-wow-delay='100ms'>
                            <div className="image-div" > <figure><img src={require("../../static/images/landing/coin2.png")} alt="" /></figure></div>
                            <h4>Exchange</h4>
                            <p>On our exchange users can do trade 24 hours 7 days a week throughout the year with really low transaction fees.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4   card-padding-left-right">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 position_images colum-2">
                          <div className="image-center text-center"><img src={require("../../static/images/landing/vectorimage2.png")} alt="" /></div>
                          <div className="card wow wow fadeInUp" data-wow-delay='200ms'>
                            <div className="image-div"> <figure><img src={require("../../static/images/landing/coin1.png")} alt="" /></figure></div>
                            <h4>UWT</h4>
                            <p>Our UWT token is a governance token which has multiple utilities that can be used across all platforms depending on your needs.</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 position_images mt-3">
                          <div className="card wow fadeInUp" data-wow-delay='300ms'>
                            <div className="image-div"> <figure><img src={require("../../static/images/landing/coin3.png")} alt="" /></figure></div>
                            <h4>High Liquidity</h4>
                            <p>On our exchange we have high liquidity. Your trades will always get filled seamlessly.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  card-padding-left">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 position_images">
                          <div className="card wow fadeInRight" data-wow-delay='100ms'>
                            <div className="image-div"><figure><img src={require("../../static/images/landing/customer5.png")} alt="" /></figure></div>
                            <h4>Lottery</h4>
                            <p>On our Lottery platform you can win and make it big using our UWT tokens. </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 position_images mt-3">
                          <div className="card wow fadeInRight" data-wow-delay='100ms'>
                            <div className="image-div"> <figure><img src={require("../../static/images/landing/customer6.png")} alt="" /></figure></div>
                            <h4>Support</h4>
                            <p>Our support team is always there for you 24 hours a day we believe in giving our clients the best possible services without any hassle .</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(WhyChooseUs);