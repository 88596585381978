export const chartData = {
  labels: [ 'Founders', 'Charity', 'Liquidity', 'Marketing', 'Developer', 'Company Reserve', 'Token ICO Sale'],
  datasets:[
    {
      label:['Founders', 'Charity', 'Liquidity', 'Marketing', 'Developer', 'Company Reserve', 'Token ICO Sale'],
      label:['Percentage %'],
      data:[
        5,
        30,
        5,
        5,
        5,
        10,
        40,
      ],
      backgroundColor:[
        'rgba(255, 99, 132, 0.6)',
        'rgba(46, 118, 168, 0.6)',
        'rgba(174, 145, 79, 0.6)',
        'rgba(57, 136, 142, 0.6)',
        'rgba(136, 104, 254, 0.6)',
        'rgba(174, 106, 40, 0.6)',
        'rgba(247, 169, 36, 0.6)',
      ],
      hoverBackgroundColor: [
        '#ef5f83',
        '#2e76a8',
        '#ae914f',
        '#39888e',
        '#8868fe',
        '#ae6a28',
        '#f7a924',
        ],
        borderWidth: 5,
        borderColor:'#333645',
        hoverBorderWidth:2,
        hoverBorderColor:'#ffffff',
        borderAlign:'inner',
    }
  ]
};


export const buyTokenChartData = {
  labels: ['Founders', 'Charity', 'Liquidity', 'Marketing', 'Developer', 'Company Reserve', 'Token ICO Sale'],
  datasets:[
    {
      label:['Founders', 'Charity', 'Liquidity', 'Marketing', 'Developer', 'Company Reserve', 'Token ICO Sale'],
      label:['Percentage %'],
      data:[
        5,
        30,
        5,
        5,
        5,
        10,
        40,
      ],
      backgroundColor:[
        'rgba(255, 99, 132, 0.6)',
        'rgba(46, 118, 168, 0.6)',
        'rgba(174, 145, 79, 0.6)',
        'rgba(57, 136, 142, 0.6)',
        'rgba(136, 104, 254, 0.6)',
        'rgba(174, 106, 40, 0.6)',
        'rgba(247, 169, 36, 0.6)',
      ],
      hoverBackgroundColor: [
        '#ef5f83',
        '#2e76a8',
        '#ae914f',
        '#39888e',
        '#8868fe',
        '#ae6a28',
        '#f7a924',
        ],
        borderWidth:7,
        borderColor:'#333645',
        hoverBorderWidth:2,
        hoverBorderColor:'#ffffff',
        borderAlign:'inner',
    }
  ]
};

  
