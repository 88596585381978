import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class FAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-lg-12 categories_main_upper">
                           
                            <h4 className="text-center text-heading22">FAQS</h4>
                        </div>
                    </div>
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12" >
                        <div className="accordion wow fadeInUp" data-wow-delay='100ms' id="faqExample">
                            <div className="card">
                                <div className="card-header p-2" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link button-faqs-togg active" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Where does the money for the prize come from?
                                            <div>
                                                <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                                <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                            </div>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#faqExample">
                                    <div className="card-body">
                                        Once you participate in a lottery your money will be locked into an escrow account. There will be a limited number of tickets assigned on every lottery. Once the lottery gets started a winner will be picked at random and will receive 90% of the total amount accumulated from all lottery players.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header p-2" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What are the risks?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                                    <div className="card-body">
                                        You can lose your capital on losing the lottery. One should not deposit more than you cant afford to lose.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header p-2" id="headingThree">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Do I have to enter for each prize?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                                    <div className="card-body">
                                        No, it's up to you which lottery prizes seem exciting and you want to get involved.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header p-2" id="headingFour">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            How do I know if I won?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#faqExample">
                                    <div className="card-body">
                                        You will be notified over the platform and also an email will be sent to your account.

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header p-2" id="headingFive">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What are my odds of winning?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#faqExample">
                                    <div className="card-body">
                                        It all depends on how many lottery tickets you buy. If you buy a max amount which is 25% you will have 4 to 1 odds of winning.

                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header p-2" id="headingSix">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        What is the token Price?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#faqExample">
                                    <div className="card-body">
                                    The token price has been locked at $1, for the time being.

                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header p-2" id="headingSeven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        Why are we raising funds?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#faqExample">
                                    <div className="card-body">
                                    We are raising funds for multiple reasons like charity and also for our future development goals and upgrades for the development team.

                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header p-2" id="headingEight">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        Benefits for stakeholders?
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#faqExample">
                                    <div className="card-body">
                                    UWT is a multipurpose coin which has different utilities like investor can hold and trade using exchange, investor can buy tickets from UWT and participate in lotteries and investor can also give charity using UWT tokens.

                                    </div>
                                </div>
                            </div>

                            <div className="card card-nine">
                                <div className="card-header p-2" id="headingNine">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        I can't see my UWT tokens and haven’t received any confirmation.
                                            <i className="icon icon-green"><img src={require("../../static/images/landing/arrow2.png")} alt="" /></i>
                                            <i className="icon icon-grey"><img src={require("../../static/images/landing/arrow1.png")} alt="" /></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#faqExample">
                                    <div className="card-body">
                                    Something might have gone wrong with your token purchase. You can get in touch with us to solve the problem. In any case the UWT will be returned to your wallet.

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);