import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class InNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <div className="col-lg-12 categories_main_upper">
                    <h5 className="text-center text-heading12">what media saying </h5>
                    <h4 className="text-center text-heading22">In The News</h4>
                </div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 card_latest_product  col-12">
                            <div className="main-card-media">
                                <div className="card_latests">
                                    <img src={require("../../static/images/landing/innew1.png")} alt="" />
                                </div>
                                <div className="textpara-new">
                                    <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 card_latest_product media-product-768-2 col-12">
                            <div className="main-card-media">
                                <div className="card_latests">
                                    <img src={require("../../static/images/landing/innew2.png")} alt="" />
                                </div>
                                <div className="textpara-new">
                                    <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 card_latest_product media-product-768-3  col-12">
                            <div className="main-card-media">
                                <div className="card_latests">
                                    <img src={require("../../static/images/landing/innew3.png")} alt="" />
                                </div>
                                <div className="textpara-new">
                                    <p>Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(InNews);