import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { web3 } from '../web3.js';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import {
  setAddress, loginToken, toggleSignup, toggleSignupInfo, setBlockchainData, setTransactionsData, setBuyTokens,
  toggleBuyModal, viewTransactionModal, toggleEmailLoader, resetContactus, setTransactionHistory
} from '../actions/Auth';

function* login({ payload }) {
  const { error, response } = yield call(getCall, `/users/getUserNonce/${payload['publicAddress']}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    try {
      var nonceObj = response['data']['body']['nonceObject'];
      // SIGING THE NONCE
      const signature = yield web3.eth.personal.sign(web3.utils.utf8ToHex(`Login Nonce: ${nonceObj["nonce"]}`), payload['publicAddress'])
      const data = {
        signature,
        type: payload['type'],
        publicAddress: payload['publicAddress'],
      }

      const metaMaskResponse = yield call(postCall, { path: `/users/login`, payload: data });
      if (error) EventBus.publish("error", error['response']['data']['message']);
      else if (metaMaskResponse) {
        let { token } = metaMaskResponse['response']['data']['body']
        const decoded = jwt_decode(token, { header: false });
        if (decoded["role"] !== "user") {
          EventBus.publish("error", "Invalid Public Address");
          yield put({ type: "IS_LOGGED_IN", payload: false });
          return;
        }
        yield put(loginToken(token));
        yield put(setAddress(payload['publicAddress']));
        EventBus.publish("success", metaMaskResponse['response']['data']['message'])
      }
    } catch (e) { yield put({ type: "IS_LOGGED_IN", payload: false }); }
  }
  yield put({ type: "IS_LOGGED_IN", payload: false });
};

function* signup({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/signup', payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) {
    yield put({
      type: 'LOGIN', payload: {
        type: 'signup',
        publicAddress: payload['publicAddress']
      }
    });
  };
  yield put(toggleSignup());
  yield put(toggleSignupInfo());
};

function* getBlockchainData() {
  const { error, response } = yield call(getCall, '/blockchain/getPublicVariables');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setBlockchainData(response['data']['body']));
};

function* sendTransactionsData({ payload }) {
  const { error, response } = yield call(postCall, { path: '/blockchain/saveTransactions', payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) {
    EventBus.publish('success', response['data']['message']);
    yield put(setTransactionsData(response['data']['body']));
    yield put(toggleBuyModal());
    yield put(viewTransactionModal());
  };
  yield put(setBuyTokens());
  yield put({ type: 'GET_BLOCKCHAIN_DATA' });
  yield put({ type: 'GET_TRANSACTION_HISTORY' });
};

function* sendContactUs({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/contact`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) {
    yield put(resetContactus());
    EventBus.publish('success', response['data']['message']);
  };
  yield put(toggleEmailLoader());
};

function* getTransactionHistory() {
  const { error, response } = yield call(getCall, '/blockchain/myTransactions');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setTransactionHistory(response['data']['body']));
};


function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('SIGN_UP', signup);
  yield takeEvery('SEND_CONTACT_US', sendContactUs);
  yield takeEvery('GET_BLOCKCHAIN_DATA', getBlockchainData);
  yield takeEvery('REQUEST_BUY_TOKEN_DATA', sendTransactionsData);
  yield takeEvery('GET_TRANSACTION_HISTORY', getTransactionHistory);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}
