import { connect } from "react-redux";
import React, { Component } from 'react';
import MuiPhoneInput from 'material-ui-phone-number';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { sendContactUs, toggleEmailLoader, resetContactus } from "../../store/actions/Auth";


class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: '',
                email: '',
                message: '',
            },
        };
    };

    componentWillReceiveProps({ isContactUs }) {
        if (isContactUs) {
            const { formData } = this.state;
            formData['name'] = '';
            formData['email'] = '';
            formData['message'] = '';
            this.setState({ formData });
        };
    }

    handleChange = (event) => {
        let { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    };

    submitContactForm = () => {
        let { formData } = this.state;
        this.props.sendContactUs(formData);
        this.props.toggleEmailLoader();
    };

    render() {

        let { formData } = this.state;
        let { isSendEmail, isContactUs } = this.props;

        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div className="col-lg-6 left-side-about">
                            <div className="text-about-company">
                                <h2>Contact Us</h2>
                                <p>If you have any query feel free to submit this form.<br></br> Team will get get back to you as soon as possiable. </p>
                            </div>
                            <div className="email-contact">
                                <p>Email: <span>  info@unitedworldtoken.com </span></p>
                            </div>
                        </div>
                        <div className="col-lg-6 right-about-contact-form">
                            <ValidatorForm className="form-contact" onSubmit={this.submitContactForm}>
                                <div className="form-group">
                                    <TextValidator
                                        fullWidth
                                        type="text"
                                        name="name"
                                        label="Name"
                                        margin="dense"
                                        value={formData['name']}
                                        className="input-fields"
                                        onChange={this.handleChange}
                                        validators={['required']}
                                        errorMessages={['Please enter the Name']}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextValidator
                                        fullWidth
                                        type="email"
                                        name="email"
                                        margin="dense"
                                        label="Email Address"
                                        className="input-fields"
                                        value={formData['email']}
                                        onChange={this.handleChange}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Please enter the Email', 'email is not valid']}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextValidator
                                        fullWidth
                                        type="text"
                                        name="message"
                                        label="Message"
                                        margin="dense"
                                        className="input-fields"
                                        value={formData['message']}
                                        onChange={this.handleChange}
                                        validators={['required']}
                                        errorMessages={['Please type your message ']}
                                    />
                                </div>
                                <div className=" button-form-contact">
                                    <button type="submit" class="btn btn-primary">
                                        {isSendEmail
                                            ? 'Submit Now'
                                            : <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                                        }
                                    </button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>

                {/* ------------------CONTACT US MODAL----------------- */}
                <Modal isOpen={isContactUs} toggle={this.props.resetContactus} className="register-modal contact-us-modal">
                    <ModalBody toggle={this.props.resetContactus} className="modal-body">
                        <ModalHeader>
                        </ModalHeader>
                        <div className="icon-area-modal-pur">
                            <img src={require("../../static/images/landing/modal-logo.png")} alt="" />
                        </div>
                        <div className="container modal-upper-pad">
                            <div className="text-center form-modal-landing-merkle">
                                <div className='row contact-content'>
                                    <div className='col-12 mb-2 sub-content'>
                                        <p>Congratulations, we have receive your request. Silver Horizon team will get back to you within 24-hours. Thanks for your corporation.</p>
                                    </div>
                                    <div className='col-12 contact-btn'>
                                        <button onClick={this.props.resetContactus}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

const mapDispatchToProps = { sendContactUs, toggleEmailLoader, resetContactus };

const mapStateToProps = ({ Auth }) => {
    let { isContactUs, isSendEmail } = Auth;
    return { isContactUs, isSendEmail };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);