import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class OurTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="col-lg-12 categories_main_upper">
                    <h5 className="text-center text-heading12">Meet our experts </h5>
                    <h4 className="text-center text-heading22">Meet The Team</h4>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 border-testi-landing border-test-landing-1">
                            <div className="testi-card-landing">
                                <div className="card-inner-landing  text-center">
                                    <img src={require("../../static/images/landing/testtimoni1.png")} alt="" />
                                    <h5>Jessica Brown</h5>
                                    <h6>Developer</h6>
                                    <div className="para-testi-landing">
                                        <p>5+ years in development <br></br>
                                        expericences Vast exprerience <br></br>
                                        in B2C on C-level positions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 border-testi-landing border-test-landing-2">
                            <div className="testi-card-landing">
                                <div className="card-inner-landing text-center">
                                    <img src={require("../../static/images/landing/testimoni2.png")} alt="" />
                                    <h5>Jessica Brown</h5>
                                    <h6>Developer</h6>
                                    <div className="para-testi-landing">
                                        <p>5+ years in development <br></br>
                                        expericences Vast exprerience <br></br>
                                        in B2C on C-level positions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 border-testi-landing border-test-landing-3">
                            <div className="testi-card-landing">
                                <div className="card-inner-landing text-center">
                                    <img src={require("../../static/images/landing/testimoni3.png")} alt="" />
                                    <h5>Jessica Brown</h5>
                                    <h6>Developer</h6>
                                    <div className="para-testi-landing">
                                        <p>5+ years in development <br></br>
                                        expericences Vast exprerience <br></br>
                                        in B2C on C-level positions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 border-testi-landings border-test-landing-4 ">
                            <div className="testi-card-landing">
                                <div className="card-inner-landing text-center">
                                    <img src={require("../../static/images/landing/testimoni4.png")} alt="" />
                                    <h5>Jessica Brown</h5>
                                    <h6>Developer</h6>
                                    <div className="para-testi-landing">
                                        <p>5+ years in development <br></br>
                                        expericences Vast exprerience <br></br>
                                        in B2C on C-level positions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);