import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class ICOWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12 categories_main_uppers category-works">
                        <h4 className="text-center text-heading">How United World ICO Works?</h4>
                        <div className="image-how-icox-work">
                            <figure><img src={require("../../static/images/landing/dotted.png")} className="icox-back-image" alt="" /></figure>
                            <div className="div-icox-one wow slideInLeft" data-wow-delay='100ms'>
                                <div className="inner-image-div">
                                    <img src={require("../../static/images/landing/blockchain.png")} alt="" />
                                </div>
                            </div>
                            <div className="div-icox-two wow slideInDown" data-wow-delay='200ms'>
                                <div className="inner-image-div">
                                    <img src={require("../../static/images/landing/link2.png")} alt="" />
                                </div>
                            </div>
                            <div className="div-icox-three wow slideInUp" data-wow-delay='200ms'>
                                <div className="inner-image-div">
                                    <img src={require("../../static/images/landing/link3.png")} alt="" />
                                </div>
                            </div>
                            <div className="div-icox-forth wow slideInDown" data-wow-delay='200ms'>
                                <div className="inner-image-div">
                                    <img src={require("../../static/images/landing/link4.png")} alt="" />
                                </div>
                            </div>
                            <div className="div-icox-fifth wow slideInRight" data-wow-delay='100ms'>
                                <div className="inner-image-div">
                                    <img src={require("../../static/images/landing/link5.png")} alt="" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div>
                    <div className="target-your-goal">
                        <h1>Target your goal <br></br> to reach out</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 "></div>
                    <div className="col-lg-8 categories_main_upper">
                        <p className="text-center">Initial United Wolrd usually starts with a new or established company looking to raise capital for growth. Traditionally, many companies turned to angel investors, venture capital or stock market offerings to raise funds.

                            For practical purposes, you can think of a digital coin as the equivalent of a stock. When a business has an ICO, it sells the coins for dollars. The new currency should theoretically track the value of the company or essential service. For this reason, the UWT treats ICOs similarly to other securities offerings and requires compliance with its laws.

                            Unlike traditional investments, ICOs may not be insured by the Securities Investor Protection Corporation (SIPC), so you have little recourse in case of fraud..</p>
                    </div>
                    <div className="col-lg-2 "></div>
                </div>
            </>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ICOWorks);