import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class Involved extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <div className="col-lg-12 categories_main_upper">
                    <h4 className="text-center text-heading22">Get Involved</h4>
                </div>
                <div className="container">


                    <div className="inner-opacity text-center">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12 offset-lg-2 offset-md-2 offset-0">
                                <div className="inner-text-involved">
                                    <h4>Join Our Growing Community</h4>
                                    <p>Make your contribution or get community support 24/7</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="outer-div">
                                            <a href="#"><img className="first-icon" src={require("../../static/images/landing/discord2.png")} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="outer-div">
                                            <a href="#"><img src={require("../../static/images/landing/discord1.png")} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="outer-div">
                                            <a href="#"><img src={require("../../static/images/landing/discord3.png")} alt="" /></a>
                                     
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="outer-div">
                                            <a href="#"><img src={require("../../static/images/landing/discord4.png")} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Involved);