import { connect } from "react-redux";
import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

import './index.css';
import {chartData} from './data.js';

class CheckoutToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="checkout-left col-lg-6 col-md-6 col-sm-12">
                        <div className="left-side">
                            <h5>Checkout token structure</h5>
                            <h4>Distribution of Tokens</h4>
                            {/* <p className="checkout-second-para">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod <br></br>
                           tempor invidunt ut labore edolore magna aliquyam erat, sed diam voluptua.</p> */}
                            <ul className="">
                                <li className="li1"><p>5% Founders</p></li>
                                <li className="li2"><p>30% Charity</p></li>
                                <li className="li3"><p>5% Liquidity</p></li>
                                <li className="li4"><p>5% Marketing</p></li>
                                <li className="li5"><p>5% Developer</p></li>
                                <li className="li6"><p>10% Company Reserve</p></li>
                                <li className="li7"><p>40% Token ICO Sale</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="checkout-right col-lg-6 col-md-6 col-sm-12">
                        <div className="right-upper-text text-center">
                            {/* <p>15% Token Pre-Sale</p> */}
                        </div>
                        <figure>
                            <Doughnut
                                data={chartData}
                                width={420}
                                height={420}
                                options={{
                                    maintainAspectRatio: false,
                                    cutoutPercentage: 30,
                                    circumference: 2 * Math.PI,
                                    legend: {
                                        position: 'top',
                                        display: true,
                                        responsive: true,
                                        align: 'start',
                                        fullWidth: true,
                                        labels: {
                                            fontColor: '#ffffff',
                                            fontSize: 12,
                                            align: 'center',
                                            padding: 5,
                                            usePointStyle: true,
                                            boxWidth: 20,
                                            margin: 5
                                        }
                                    },
                                   
                                    layout: {
                                        padding: {
                                            left: 0,
                                            right: 0,
                                            top: 0,
                                            bottom: 0
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: 'Tokenomics',
                                        color: '#ffffff',
                                        fontSize: 18,
                                        fontColor: '#ffffff'
                                    },
                                    showDatasetLabels: true,
                                }
                                }
                            />
                        </figure>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutToken);