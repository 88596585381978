import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import './index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='footer-fot'>
                {/* <div className="image-footer">
                    <img src={require("../../static/images/landing/imagefooter.png")} alt="" />
                </div> */}
                <div className="footer">
                    {/* <h5 className="text-center">Get your team started on today</h5>
                    <div className="button-fot">
                        <div className="buttonIn button-in1 button-in2">
                            <input type="text" placeholder="Enter email address.." id="enter" />
                            <button id="clear"><i className="fa fa-paper-plane" aria-hidden="true"></i>Get a Demo</button>
                        </div>
                    </div>
                    <h6 className="text-center pt-3">Guided Onboarding. Try It Free for 30 Days. Cancel Anytime.</h6>
                     */}
                    <div className="container ">
                        <div className="row footer-media-1 footer-media-2">
                            <div className="col-lg-3 col-md-6 col-12 footer-div ">
                                <div className="bullet_pointer ">
                                    <h3 className="text-white">Tokens Sales</h3>
                                    <ul>
                                      
                                        <li><HashLink smooth to='/#ico-work'>How UWT works</HashLink></li>
                                     
                                        <li><HashLink smooth to='/#token-distribution'>Token Distribution </HashLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 footer-div ">
                                <div className="bullet_pointer ">
                                    <h3 className="text-white">RESOURCES</h3>
                                    <ul>
                                        {/* <li><a href="# ">About Us</a></li>
                                        <li><a href="# ">Faqs</a></li> */}
                                          <li><HashLink smooth to='/#roadmap'>Road Map</HashLink></li>
                                        <li><HashLink smooth to='/#faq'> FAQs</HashLink></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 footer-divs">
                                <div className="bullet_pointer ">
                                    <h3 className="text-white">COMPANY</h3>
                                    <ul className="">
                                        {/* <li><a href="# ">News</a></li> */}
                                        {/* <li><HashLink smooth to='/#news'>News</HashLink></li> */}
                                        <li><HashLink smooth to='/#about'>About Us</HashLink></li>
                                        <li><HashLink smooth to='/#why-how'>Why choose us </HashLink></li>
                                        <li><HashLink smooth to='/#get-involved'>Get Involved </HashLink></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 footer-divs">
                                <div className="bullet_pointer ">
                                    <h3 className="text-white">SUPPORT</h3>
                                    <ul className="">
                                        {/* <li className=""><a href="# ">1330 Cooks Mine Road
                                        Twin Lakes, Mexico - 86515
                                       </a></li>
                                        <li><a href="# "> +505-735-0177</a></li> */}
                                        <li><a href="# "> info@unitedworldtoken.com </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row copy-right">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="leftside-footer">
                                    <p>Copyright © 2021, United World Token</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="rightside-footer d-flex float-right">
                                    <p className="">Follow On Us:</p>
                                    <div className="footer-socialss social-ones text-center">
                                    <a href="" className="social-one"><span className="pr-4"><i className="fa fa-twitter" aria-hidden="true"></i></span></a>
                                    </div>
                                    <div className="footer-socialss social-twos">
                                    <a href="" className="social-two"> <span className="pr-4"><i className="fa fa-facebook-f" aria-hidden="true"></i></span></a>
                                    </div>
                                    <div className="footer-socialss social-threes">
                                    <a href="" className="social-three"><span className="pr-4"><i className="fa fa-pinterest" aria-hidden="true"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;