import moment from 'moment';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

import './index.css';
import Web3 from 'web3';
import { buyTokenChartData } from './data.js';
import { getBlockchainData, toggleBuyModal } from "../../store/actions/Auth";


class BuyTokens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rate: '',
            minCap: '',
            maxCap: '',
            weiRaised: '',
        };
        props.getBlockchainData();
    };

    componentWillReceiveProps({ blockchainData }) {
        this.setState({
            rate: blockchainData && blockchainData['rate'] ? Web3.utils.fromWei(blockchainData['rate'].toString(), 'ether') : '0',
            minCap: blockchainData && blockchainData['investorMinCap'] ? Web3.utils.fromWei(blockchainData['investorMinCap'].toString(), 'ether') : '0',
            maxCap: blockchainData && blockchainData['investorHardCap'] ? Web3.utils.fromWei(blockchainData['investorHardCap'].toString(), 'ether') : '0',
            weiRaised: blockchainData && blockchainData['weiRaised'] ? Web3.utils.fromWei(blockchainData['weiRaised'].toString(), 'ether') : '0',
        });
    }

    handleModal = () => {
        let { address } = this.props;
        if (address == null) {
            EventBus.publish('info', 'Please Connect to your Wallet');
            return;
        };
        this.props.toggleBuyModal();
    }

    render() {
        let { blockchainData } = this.props;
        let { rate, minCap, maxCap, weiRaised } = this.state;
        return (
            <div>
                <div className="col-lg-12 categories_main_upper">
                    <h4 className="text-center text-heading2">Token Sale</h4>
                </div>
                {blockchainData && Object.keys(blockchainData).length > 0 &&
                    <div className="container">
                        <div className="row">
                            <div className="checkout-left col-lg-7 col-md-12 col-sm-12">
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <div>
                                            <p>Start of ICO:</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <div>
                                            <p>{moment(blockchainData['openingTime'] * 1000).format('llll')}</p>
                                        </div>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5 ">
                                        <p>End of ICO:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{moment(blockchainData['closingTime'] * 1000).format('llll')}</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <p>UWT token price:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{rate} USDT</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <p>Max amount of tokens to be sold:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{blockchainData['tokensSold'].toFixed(2)} out of {blockchainData['totalTokens'].toFixed(2)} ({blockchainData['range'].toFixed(2)} %)</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <p>ICO hard cap:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{maxCap} USDT</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <p>Min purchase amount:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{minCap} USDT</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                                <div className="row token-bit">
                                    <div className="col-lg-5 col-md-5">
                                        <p>USDT Raised:</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p>{weiRaised} USDT</p>
                                    </div>
                                    <hr className="hrbitcoin"></hr>
                                </div>
                            </div>
                            <div className="checkout-right col-lg-5 col-md-12 col-sm-12">
                                <figure>
                                    <Doughnut
                                        data={buyTokenChartData}
                                        width={300}
                                        height={300}
                                        options={{
                                            maintainAspectRatio: false,
                                            cutoutPercentage: 30,
                                            legend: {
                                                position: 'top',
                                                display: true,
                                                responsive: true,
                                                align: 'center',
                                                fullWidth: true,
                                                labels: {
                                                    fontColor: '#ffffff',
                                                    fontSize: 12,
                                                    align: 'center',
                                                    padding: 5,
                                                    usePointStyle: true,
                                                    boxWidth: 20,
                                                    margin: 5
                                                }
                                            },
                                            layout: {
                                                padding: {
                                                    left: 0,
                                                    right: 0,
                                                    top: 0,
                                                    bottom: 0
                                                }
                                            },
                                            title: {
                                                display: false,
                                                text: 'Buy Token Percentage',
                                                color: '#ffffff',
                                                fontSize: 18,
                                                fontColor: '#ffffff'
                                            },
                                            showDatasetLabels: true,
                                        }
                                        }
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
};


const mapDispatchToProps = {
    getBlockchainData,
    toggleBuyModal,
};

const mapStateToProps = ({ Auth }) => {
    let { address, blockchainData } = Auth;
    return { address, blockchainData }
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyTokens);