import Web3 from 'web3';
import moment from 'moment';
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import Slider from 'react-rangeslider';
import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { getBlockchainData, toggleBuyModal, getTransactionHistory } from "../../store/actions/Auth";

import './index.css';
import 'react-rangeslider/lib/index.css'

class CountDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            range: 0,
            rate: '-',
            goal: '-',
            totalTokens: '-',
            isViewModal: false,
            icoState: '-', // preLaunched | Launched | Failed | Successful
        };
        props.getBlockchainData();
    };

    componentDidMount() {
        if (this.props.auth) this.props.getTransactionHistory();
    }

    componentWillReceiveProps({ blockchainData }) {
        if (blockchainData['range'] >= 0) this.setState({ range: blockchainData['range'] });
        if (blockchainData['totalTokens']) this.setState({ totalTokens: blockchainData['totalTokens'] });
        if (blockchainData['rate']) this.setState({ rate: Web3.utils.fromWei(blockchainData['rate'].toString(), 'ether') });
        if (blockchainData['goal']) this.setState({ goal: Web3.utils.fromWei(blockchainData['goal'].toString(), 'ether') });
        if (blockchainData['cap']) {
            let remainingAmount = Number(Web3.utils.fromWei(blockchainData['cap'].toString(), 'ether')) - Number(Web3.utils.fromWei(blockchainData['weiRaised'].toString(), 'ether'));
            let canInvest = remainingAmount >= Web3.utils.fromWei(blockchainData['investorMinCap'].toString(), 'ether');

            /* If launch time is haven't reached */
            if (blockchainData['icoState'] == 'Launched' && !canInvest) this.setState({ icoState: 'Completed' });
            else this.setState({ icoState: blockchainData['icoState'] });
        }
    };

    handleModal = () => {
        let { address } = this.props;
        if (address == null) {
            EventBus.publish('info', 'Please Connect to your Wallet');
            return;
        };
        this.props.toggleBuyModal();
    };

    toggleViewModal = () => {
        this.setState({ isViewModal: !this.state.isViewModal })
    }

    handleReload = () => {
        window.location.reload(false);
        EventBus.publish('info', "ICO Time is Completed");
    };

    render() {
        let { auth, blockchainData, transactionHistory } = this.props;
        let { goal, rate, range, icoState, totalTokens, isViewModal } = this.state;

        const Completionist = () => <span>You are good to go!</span>;
        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) return <Completionist />;
            else {
                return (
                    <div className="d-flex text-center count-star justify-content-center align-items-center">
                        <span>
                            <h2>{days ? days : '-'}</h2>
                            <p>Days</p>
                        </span>
                        <span>
                            <h2>{hours ? hours : '-'}</h2>
                            <p>Hours</p>
                        </span>
                        <span>
                            <h2>{minutes ? minutes : '-'}</h2>
                            <p>Minutes</p>
                        </span>
                        <span>
                            <h2>{seconds ? seconds : '-'}</h2>
                            <p>Seconds</p>
                        </span>
                    </div>
                );
            }
        };

        return (
            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="countdown-main">
                            <div className="text-center">
                                <h6>Round One</h6>
                                <h4>ICO is {icoState == 'preLaunched' ? 'Coming Soon' : icoState}</h4>
                            </div>
                            <div className="counter-mon-min-sec">
                                {blockchainData && blockchainData['closingTime']
                                    &&
                                    <div className="token-condition">
                                        {icoState === 'preLaunched' ?
                                            <Countdown date={Date.now() + parseInt((blockchainData['openingTime'] * 1000) - Date.now())} renderer={renderer} onComplete={this.handleReload} />
                                            : icoState === 'Launched' ?
                                                <Countdown date={Date.now() + parseInt((blockchainData['closingTime'] * 1000) - Date.now())} renderer={renderer} onComplete={this.handleReload} />
                                                : icoState === 'Failed' ?
                                                    <p>Token Offering has failed because goal was not reached in specified time! If you have invested your funds will be sent back to your wallet.</p>
                                                    : <p> Token Offering has successfully completed! </p>
                                        }
                                    </div>
                                }
                            </div>

                            <div className="d-flex justify-content-around middel-line">
                                <p className="uwt-one">{goal} UWT </p>
                                <p className="uwt-two">1 UWT = {rate} USDT</p>
                                <p className="uwt-three">{totalTokens} UWT</p>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-12">
                                    <div className={`token-slider slider-first`}>
                                        <Slider
                                            min={0}
                                            max={100}
                                            tooltip={false}
                                            value={range}
                                            handleLabel={parseFloat(range).toFixed(2) + ' %'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`softline ${range > 5 && 'yellow-line'}`}>
                                <h4 className={range > 5 ? 'yellow-text' : 'white-text'}>Soft Cap</h4>
                            </div>
                            <div className={`softline-two ${range > 75 && 'yellow-line'}`}>
                                <h4 className={range > 75 ? 'yellow-text' : 'white-text'}>Hard Cap</h4>
                            </div>

                            <div className="text-center button-section">
                                {blockchainData && icoState === 'Launched' &&
                                    <button onClick={this.handleModal}>Buy Token Now</button>
                                }
                                {transactionHistory.length > 0 && auth &&
                                    <button className="ml-3" onClick={this.toggleViewModal}>View Transactions</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div> */}

                {/* ------------------VIEW TRANSACTION MODAL----------------- */}
                <Modal isOpen={isViewModal} toggle={this.toggleViewModal} className="register-modal transaction-history-modal">
                    <ModalBody className="modal-body">
                        <ModalHeader toggle={this.toggleViewModal}></ModalHeader>
                        <div className="icon-area-modal-pur">
                            <img src={require("../../static/images/landing/modal-logo.png")} alt="" />
                        </div>
                        <div className="container modal-upper-pad">
                            <div className="text-center mt-4 form-modal-landing-silver transaction-history">
                                <table class="table table-striped transaction-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Tx Hash</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Public Address</th>
                                            <th scope="col">Invest Amount</th>
                                            <th scope="col">Amount of Tokens</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionHistory.map(data => {
                                            return (
                                                <tr>
                                                    <th scope="row">{data['transactionHash'] ? <a className="transaction-hash" target="_blank" href={`https://rinkeby.etherscan.io/tx/${data['transactionHash']}`}>{data['transactionHash'].substring(0, 7) + '...'}</a> : '-'}</th>
                                                    <td>{data['transactionTime'] ? moment(data['transactionTime']).format('ll') : '-'}</td>
                                                    <td>{data['publicAddress'] ? data['publicAddress'].substring(0, 7) + '...' : '-'}</td>
                                                    <td>{data['investedAmount'] ? data['investedAmount'] : '-'}</td>
                                                    <td>{data['amountOfTokens'] ? data['amountOfTokens'] : '-'}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }

}

const mapDispatchToProps = {
    getBlockchainData,
    toggleBuyModal,
    getTransactionHistory
};

const mapStateToProps = ({ Auth }) => {
    let { auth, blockchainData, address, transactionHistory } = Auth;
    return { auth, blockchainData, address, transactionHistory }
};

export default connect(mapStateToProps, mapDispatchToProps)(CountDown);