import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Landing from './landing';
import { web3 } from "../store/web3";
import { networkId, message } from "../store/config";
import { logout } from "../store/actions/Auth";

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import 'font-awesome/css/font-awesome.min.css';

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => { toast.error(() => <div> <Error /> {e}</div>) });
    EventBus.on('success', (e) => { toast.success(() => <div> <CheckCircle /> {e}</div>) });
  };

  checkEthereum = () => {
    if (typeof window.ethereum !== "undefined") {

      // check network
      web3.eth.net.getId((err, netId) => {
        console.log('*******Network ID = ', netId);
        if (netId != networkId) {
          // let { isPolicyModal } = this.props;
          // if (!isPolicyModal) this.props.toggleSwitchModal(true);
          EventBus.publish('info', message);
        }
      });

      // if (window.ethereum.currentProvider.isMetaMask) {
      window.ethereum.on("accountsChanged", accounts => {
        if (this.props.auth) {
          this.props.logout();
          // this.props.getBlockchainData();
          // this.props.getTransactionHistory();
          // this.props.resetWallet();
          // this.props.resetExchange();
          // this.props.resetSettings();
        }
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        if (netId != networkId) {
          this.props.logout();
          // this.props.getBlockchainData();
          // this.props.getTransactionHistory();
          // this.props.resetWallet();
          // this.props.resetExchange();
          // this.props.resetSettings();
          EventBus.publish("info", "Network has been changed");
        }
      });
      // }
    }
  };

  render() {

    return (
      <div>
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <Landing {...props} />} />
            <Route exact path='/landing' component={props => <Landing {...props} />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout,
  // getBlockchainData,
  // getTransactionHistory,
};

const mapStateToProps = ({ Auth }) => {
  let { auth, isPolicyModal } = Auth;
  return { auth, isPolicyModal }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);