import { connect } from "react-redux";
import React, { Component } from 'react';

import './index.css';

class RoadMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <div className="col-lg-12 categories_main_upper">
                    <h5 className="text-center text-heading12">How it started </h5>
                    <h4 className="text-center text-heading22">Our Roadmap</h4>
                </div>
                <div className="container">
                    <div className="month-roadmap">
                        <div className="d-flex text-center road-inner justify-content-center align-items-center nav" id="v-pills-tab">
                            <a className="nav-linkss " id="v-pills-2020-tab" data-toggle="pill" href="#v-pills-2020" role="tab" aria-controls="v-pills-2020" aria-selected="true">
                                <span>
                                    <p>Q3 <br></br>2021</p>
                                    <div className="circle-roadmap"></div>
                                </span>
                            </a>

                            <a className="nav-linkss active" id="v-pills-2021-tab" data-toggle="pill" href="#v-pills-2021" role="tab" aria-controls="v-pills-2021" aria-selected="false">
                                <span>
                                    <p>Q4<br></br>2021</p>
                                    <div className="circle-roadmap"></div>
                                </span>
                            </a>

                            <a className="nav-linkss" id="v-pills-2022-tab" data-toggle="pill" href="#v-pills-2022" role="tab" aria-controls="v-pills-2022" aria-selected="false" >
                                <span>
                                    <p>Q1<br></br>2022</p>
                                    <div className="circle-roadmap"></div>
                                </span>
                            </a>

                            <a className="nav-linkss" id="v-pills-2023-tab" data-toggle="pill" href="#v-pills-2023" role="tab" aria-controls="v-pills-2023" aria-selected="false">
                                <span>
                                    <p>Q2<br></br>2022</p>
                                    <div className="circle-roadmaps"></div>
                                </span>
                            </a>

                            {/* <a className="nav-linkss" id="v-pills-2024-tab" data-toggle="pill" href="#v-pills-2024" role="tab" aria-controls="v-pills-2024" aria-selected="false">
                                <span>
                                    <p>2025<br></br>Oct</p>
                                    <div className="circle-roadmap"></div>
                                </span>
                            </a>

                            <a className="nav-linkss" id="v-pills-2025-tab" data-toggle="pill" href="#v-pills-2025" role="tab" aria-controls="v-pills-2025" aria-selected="false">
                                <span>
                                    <p>2026<br></br>Dec</p>
                                </span>
                            </a> */}

                        </div>
                    </div>
                    <div className="tab-content wow fadeInLeft" data-wow-delay='100ms' id="v-pills-tabContent">

                        <div className="tab-pane fade" id="v-pills-2020" role="tabpanel" aria-labelledby="v-pills-2020-tab">
                            <div className="paragraph-landing-roadmap-two ">
                                <h2> Launch of UWT on IDO platforms </h2>
                                <p>UWT will be launched on multiple IDO platforms like Switchswap, Uniswap, Pancake swap etc.</p>
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="v-pills-2021" role="tabpanel" aria-labelledby="v-pills-2021-tab">
                            <div className="paragraph-landing-roadmap">
                                <h2>United world Exchange platform and Lottery platform launch</h2>
                                <p>The United World exchange platform will be launched in the last quarter of 2021 it will boost the utility of the token.
                                    <br />
                                    The United world lottery platform will also be launched in the same quarter.
                                </p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-2022" role="tabpanel" aria-labelledby="v-pills-2022-tab">
                            <div className="paragraph-landing-roadmap-three">
                                <h2>Poker Game Launch</h2>
                                <p>Poker game utilising UWT will be launched in early 2022 with the aim of adding more utility for the token.</p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-2023" role="tabpanel" aria-labelledby="v-pills-2023-tab">
                            <div className="paragraph-landing-roadmap-four">
                                <h2>Extensive Marketing and Airdrops</h2>
                                <p>In second quarter of 2022 UWT will start extensive and aggressive marketing about the token and also will give free airdrops to the current investors.</p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-2024" role="tabpanel" aria-labelledby="v-pills-2024-tab">
                            <div className="paragraph-landing-roadmap-five">
                                <h2>Hypersmart Contract:</h2>
                                <p>Start getting hyper smart contracts. It was the time for the successful startup and real development started.</p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-2025" role="tabpanel" aria-labelledby="v-pills-2025-tab">
                            <div className="paragraph-landing-roadmap-six">
                                <h2>Start of the ICO Crypto Development.</h2>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore edolore magna aliquyam <br></br> erat, sed diam voluptua. At vero eos accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet is a legere explicari vis in <br></br> scaevola reprimique eloquentiam at eos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
    // let {  } = Auth;
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(RoadMap);