import moment from 'moment';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Web3 from 'web3';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import { ICO, ICOAddress, StableToken, StableTokenAddress, Token, TokenAddress } from '../../store/contract';
import { togglePolicyModal, getBlockchainData, toggleConnectModal, toggleSwitchModal, toggleBuyModal, setBuyTokens, sendTransactionsData, viewTransactionModal } from "../../store/actions/Auth";

import './index.css';
import FAQs from './faqs';
import InNews from './inNews';
import RoadMap from './roadMap';
import OurTeam from './ourTeam';
import ICOWorks from './icoWorks';
import Involved from './invovled';
import ContactUs from './contact-us';
import CountDown from './countDown';
import BuyTokens from './buyTokens';
import WhyChooseUs from './whyChooseUs';
import CheckoutToken from './checkoutToken';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import '../../static/css/animate.css';

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        ether: '',
        scrollAmount: 10,
      },
      rate: '',
      minCap: '',
      maxCap: '',
      remainingUSDT: '',
      transactionHash: '',
    };
    props.getBlockchainData();
  };

  componentDidMount() {
    import('wowjs').then((WOW) => new WOW.WOW({
      boxClass: 'wow',           // animated element css class (default is wow)
      animateClass: 'animated',  // animation css class (default is animated)
      offset: 5,                 // distance to the element when triggering the animation (default is 0)
      mobile: true,              // trigger animations on mobile devices (default is true)
      live: true,                // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null      // optional scroll container selector, otherwise use window
    }).init());
  };

  componentWillReceiveProps({ blockchainData }) {
    this.setState({
      rate: blockchainData && blockchainData['rate'] ? Web3.utils.fromWei(blockchainData['rate'].toString(), 'ether') : '0',
      minCap: blockchainData && blockchainData['investorMinCap'] ? Web3.utils.fromWei(blockchainData['investorMinCap'].toString(), 'ether') : '0',
      maxCap: blockchainData && blockchainData['investorHardCap'] ? Web3.utils.fromWei(blockchainData['investorHardCap'].toString(), 'ether') : '0',
      remainingUSDT: blockchainData && blockchainData['cap'] ? (Number(Web3.utils.fromWei(blockchainData['cap'].toString(), 'ether')) - Number(Web3.utils.fromWei(blockchainData['weiRaised'].toString(), 'ether'))) : '0',
    });
  }

  buyTokens = async () => {
    try {
      let { formData } = this.state;
      let { ether } = this.state.formData;
      let { isBuyTokens, blockchainData } = this.props;

      if (ether == '') {
        EventBus.publish('error', 'Please enter the some number');
        return;
      };
      if (Number(ether) < Number(this.state.minCap)) {
        EventBus.publish('error', `Please enter the amount equal or greater then ${this.state.minCap} USDT`);
        return;
      };
      if (Number(ether) > Number(this.state.maxCap)) {
        EventBus.publish('error', `Please enter the amount equal or less then ${this.state.maxCap} USDT`);
        return;
      };
      if (Number(ether) > Number(this.state.remainingUSDT)) {
        EventBus.publish('error', `Your Investment is greater than ${this.state.remainingUSDT} USDT available to invest`);
        return;
      };

      this.props.setBuyTokens();

      ether = Web3.utils.toWei(ether.toString(), 'ether')
      let address = (await web3.currentProvider.enable())[0];
      const balanceInWei = await web3.eth.getBalance(address);
      const balanceStableCoin = await StableToken.methods.balanceOf(address).call();

      if (parseFloat(ether) > parseFloat(balanceStableCoin)) {
        this.props.setBuyTokens();
        EventBus.publish('error', 'Your USDT balance is less than amount you entered');
        return;
      };

      /** Approve USDT to ICO Address */
      await web3.eth.sendTransaction({
        from: address,
        value: 0,
        to: StableTokenAddress,
        gasPrice: web3.utils.toHex(web3.utils.toWei('5', 'gwei')),
        data: StableToken.methods.approve(ICOAddress, ether).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash));

      /** Invest in ICO */
      await web3.eth.sendTransaction({
        from: address,
        value: 0,
        to: ICOAddress,
        gasPrice: web3.utils.toHex(web3.utils.toWei('5', 'gwei')),
        data: ICO.methods.buyTokens(address, ether).encodeABI(),
      })
        .on('transactionHash', (hash) => console.log(`******* buyTokensHash = `, hash))
        .on('receipt', async buyReceipt => {
          formData['ether'] = '';
          this.setState({ transactionHash: buyReceipt['transactionHash'], formData });
          this.props.sendTransactionsData({ transactionHash: buyReceipt['transactionHash'] });
        });
    } catch (error) {
      this.props.setBuyTokens();
      console.log('*************error = ', error);
      EventBus.publish('error', 'Unable to invest right now, Please contact customer support!');
    }
  };

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  copied = () => EventBus.publish("success", 'Wallet Address Copied');

  handleScrollOver = () => document.getElementById(`nav-marquee`).stop();

  handleScrollOut = () => document.getElementById(`nav-marquee`).start();

  render() {
    let { isPolicyModal, isConnectedModal, isSwitchModal, isBuyModal, isBuyTokens, isTransactionModal, buyToken } = this.props;
    let { formData, scrollAmount, rate, minCap, maxCap, remainingUSDT } = this.state;
    return (
      <div className='landing-silver'>
        <div className="header" id="topheader" style={{ backgroundImage: `url(${require("../../static/images/landing/silverbackground.png")})` }}>

          <div className='landing-marquee'>
            <marquee className='' scrollamount={scrollAmount} id='nav-marquee'
              onMouseOver={this.handleScrollOver} onMouseOut={this.handleScrollOut}>
              <div className='row marquee-container'>
                <div className="display-f">
                  <p className='token-title'> UWT Token address on Rinkeby Test Network: &nbsp; </p>
                  <a className="token-address" target="_blank" href={`${explorer}/address/${TokenAddress}`}> {TokenAddress} </a>
                  <CopyToClipboard text={TokenAddress} onCopy={this.copied}>
                    <Link to="#"><i class="fa fa-clone margin-give" aria-hidden="true"></i></Link>
                  </CopyToClipboard>
                </div>
              </div>
            </marquee>
          </div>

          <Navbar />
          {/* Haeder Section */}
          <section className="header-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                  <div className="textheader-main wow slideInLeft" data-wow-delay='100ms'>
                    <h2>Spread joy with UWT</h2>
                    <p>  United World token is a first of it’s kind token to serve the social cause of charity. It will be leveraged by a complete ecosystem where it will be used which will increase demand and liquidity. Invest in UWT, buy a token before it publically traded.</p>
                    <a target="_blank" href="https://uwt-white-paper.s3.us-east-2.amazonaws.com/Whitepaper.docx.pdf">Get White Paper</a>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                  <div className="imageback-header wow slideInRight" data-wow-delay='100ms'>
                    <img src={require("../../static/images/landing/banner-new.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Logo's Section */}
        {/* <div className="logosss">
          <div className="container">
            <div className="row logo-mar">
              <div className="col-lg-1"></div>
              <div className="col-lg-2 col-md-4 col-6 logo-image-landing">
                <div className="logos-padd">
                  <img src={require("../../static/images/landing/img12.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 logo-image-landing ">
                <div className="logos-padd">
                  <img src={require("../../static/images/landing/img13.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 logo-image-landing">
                <div className="logos-padd">
                  <img src={require("../../static/images/landing/img14.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 logo-image-landing logo-image-landing-4">
                <div className="logos-padd">
                  <img src={require("../../static/images/landing/img15.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 logo-image-landing logo-image-landing-5">
                <div className="logos-padd">
                  <img src={require("../../static/images/landing/img16.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div> */}

        {/* CountDown Section */}
        <section className="countdown">
          <CountDown {...this.props} />
        </section>

        {/* RoadMap Section */}
        <section id='roadmap' className="our-roadmap">
          <RoadMap {...this.props} />
        </section>

        {/* Checkout-Token Section */}
        <section id="token-distribution" className="checkout-token-structure">
          <CheckoutToken {...this.props} />
        </section>

        {/*How-Works Section */}
        <section className="how-works">

          {/* Why-Choose-Us */}
          <div id="why-how" className="why-choose-us">
            <WhyChooseUs {...this.props} />
          </div>

          {/* ICO-Works */}
          <div id="ico-work" className="container  mb-5">
            <ICOWorks {...this.props} />
          </div>

          {/* Buy-Our-Token */}
          <div id="token" className="buy-our-token">
            <BuyTokens {...this.props} />
          </div>

          {/* In-News */}
          {/* <div id="news" className="in-the-news">
            <InNews {...this.props} />
          </div> */}

          {/* Our-Team */}
          {/* <div id="team" className="our-team">
            <OurTeam {...this.props} />
          </div> */}

          {/* FAQ's */}
          <div id="faq" className="faqs-sec">
            <FAQs {...this.props} />
          </div>

          {/* Involved */}
          <div id="get-involved" className="get-involved">
            <Involved {...this.props} />
          </div>

          <section className="contact-us" id="contact-us">
            <ContactUs {...this.props} />
          </section>

          <Footer />
        </section>

        {/* ------------------COOKIE's & PRIVACY POLICY MODAL----------------- */}
        <Modal isOpen={isPolicyModal} className="register-modal privacy-modal">
          <ModalBody className="modal-body">
            <ModalHeader>
            </ModalHeader>
            <div className="icon-area-modal-pur">
              <img src={require("../../static/images/landing/06.png")} alt="" />
            </div>
            <div className="container modal-upper-pad">
              <div className="text-center form-modal-landing-silver">
                <div className='row policy-content'>
                  <div className='col-12 mb-2 text-center'><h4>Privacy and Cookie Policy</h4></div>
                  <div className='col-12 mb-2 sub-content'>
                    <p>This website uses cookies to ensure you get the best experience on our website.</p>
                  </div>
                  <div className='col-12 privacy-btn'>
                    <button onClick={this.props.togglePolicyModal}>Got it!</button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ------------------CONNECT-WALLET MODAL----------------- */}
        <Modal isOpen={isConnectedModal} toggle={() => this.props.toggleConnectModal(false)} className="register-modal connect-modal">
          <ModalBody className="modal-body">
            <ModalHeader toggle={() => this.props.toggleConnectModal(false)}></ModalHeader>
            <div className="icon-area-modal-pur">
              <img src={require("../../static/images/landing/06.png")} alt="" />
            </div>
            <div className="container modal-upper-pad">
              <div className="text-center form-modal-landing-silver">
                <h4 className="mb-4">Please install Metamask extension. You can click learn more to explore all the steps.</h4>
                <h6><a target="_blank" href="https://levelup.gitconnected.com/how-to-use-metamask-a-step-by-step-guide-f380a3943fb1">Learn More</a></h6>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ------------------SWITCH-NETWORK MODAL----------------- */}
        <Modal isOpen={isSwitchModal} toggle={() => this.props.toggleSwitchModal(false)} className="register-modal connect-modal">
          <ModalBody className="modal-body">
            <ModalHeader toggle={() => this.props.toggleSwitchModal(false)}>
            </ModalHeader>
            <div className="icon-area-modal-pur">
              <img src={require("../../static/images/landing/06.png")} alt="" />
            </div>
            <div className="container modal-upper-pad">
              <div className="text-center form-modal-landing-silver">
                <h4 className="mb-4">Please switch your Network to Rinkeby Test Network.</h4>
              </div>
            </div>
          </ModalBody>
        </Modal>


        {/* ------------------BUY-TOKEN's MODAL----------------- */}
        <Modal isOpen={isBuyModal} toggle={() => this.props.toggleBuyModal()} className="register-modal buy-token-modal">
          <ModalBody className="modal-body" >
            <ModalHeader toggle={() => this.props.toggleBuyModal()}></ModalHeader>
            <div className="icon-area-modal-pur">
              <img src={require("../../static/images/landing/06.png")} alt="" />
            </div>
            <div className="container modal-upper-pad">
              <div className="text-center form-modal-landing-silver">
                <div className="input-feild-button">
                  <ValidatorForm className="row validator-form mt-4" onSubmit={this.buyTokens}>
                    <div className="col-lg-8 col-md-8 col-12 offset-lg-2 offset-md-2 offset-sm-0 offset text-feild-modal">
                      <TextValidator
                        className="login-input-fields"
                        type="text"
                        name="ether"
                        label="USDT to Invest"
                        margin="dense"
                        variant="outlined"
                        onChange={this.handleChange}
                        value={formData['ether']} />
                    </div>
                    <div className='buy-token-table offset-2 col-8 mt-3'>
                      <table class="table table-hover">
                        <tbody>
                          <tr>
                            <td><span>Price</span></td>
                            <td>{rate} USDT</td>
                          </tr>
                          <tr>
                            <td><span>No. of Tokens</span></td>
                            <td>{formData['ether'] !== null
                              ? formData['ether'] / rate + ' UWT'
                              : '-'
                            }</td>
                          </tr>
                          <tr>
                            <td><span>Minimum</span></td>
                            <td>{minCap} USDT</td>
                          </tr>
                          <tr>
                            <td><span>Maximum</span></td>
                            <td>{maxCap} USDT</td>
                          </tr>
                          <tr>
                            <td><span>Remaining</span></td>
                            <td>{remainingUSDT} USDT</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='col-12'>
                      <button type="Submit" variant="contained" className='button-form-modal text-black login-btn'>
                        {isBuyTokens
                          ? 'Buy Tokens'
                          : <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                        }
                      </button>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer"></ModalFooter>
        </Modal>

        {/* ------------------VIEW TRANSACTION MODAL----------------- */}
        <Modal isOpen={isTransactionModal} toggle={() => this.props.viewTransactionModal()} className="register-modal view-transaction-modal">
          <ModalBody className="modal-body" >
            <ModalHeader toggle={() => this.props.viewTransactionModal()}>
            </ModalHeader>
            <div className="icon-area-modal-pur">
              <img src={require("../../static/images/landing/06.png")} alt="" />
            </div>
            <div className="container modal-upper-pad">
              <div className="text-center form-modal-landing-silver">
                <h4>View Transaction on Rinkeby Test Network</h4>
                <div className="transaction-details">
                  <a target="_blank" href={`https://rinkeby.etherscan.io/tx/${buyToken['ViewOnRinkeby']}`}>Transaction Details</a>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer"></ModalFooter>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  togglePolicyModal,
  toggleConnectModal,
  toggleSwitchModal,
  getBlockchainData,
  toggleBuyModal,
  setBuyTokens,
  sendTransactionsData,
  viewTransactionModal,
};

const mapStateToProps = ({ Auth }) => {
  let { blockchainData, isPolicyModal, isConnectedModal, isSwitchModal, isBuyModal, isBuyTokens, buyToken, isTransactionModal } = Auth;
  return { blockchainData, isPolicyModal, isConnectedModal, isSwitchModal, isBuyModal, isBuyTokens, buyToken, isTransactionModal }
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);